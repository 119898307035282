import React, { useEffect } from "react"
import Layer from "../components/Layer"
import Seo from "../components/Seo"
import "../css/style.css"
import da from "../js/dynamic_adapt"
import { spollers } from "../js/functions"

const TermsAndContidions = () => {
  useEffect(() => {
		da.init()
		spollers()
	}, [])
  return (
    <>
      <Seo title="Terms and conditions"/>
      <Layer>
        <main className="terms_and_conditions_main privacy_policy_main" style={{marginBottom: '40px'}}>
          <div>
          <hr />
          <br />
          <ol>
            <frameset>
              <frame src="https://www.ukheshe.com/solmatetermsandconditions" />
            </frameset>
          </ol>
          <br />

          {/* <div id="volume-booster-visusalizer">
            <div class="sound">
              <div class="sound-icon"></div>
              <div class="sound-wave sound-wave_one"></div>
              <div class="sound-wave sound-wave_two"></div>
              <div class="sound-wave sound-wave_three"></div>
            </div>
            <div class="segments-box">
              <div data-range="1-20" class="segment">
                <span></span>
              </div>
              <div data-range="21-40" class="segment">
                <span></span>
              </div>
              <div data-range="41-60" class="segment">
                <span></span>
              </div>
              <div data-range="61-80" class="segment">
                <span></span>
              </div>
              <div data-range="81-100" class="segment">
                <span></span>
              </div>
            </div>
          </div> */}
                      
          </div>
        </main>
      </Layer>
    </>
  )
}

export default TermsAndContidions
